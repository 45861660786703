.wrapper {
	position: relative;
	width: 100vw;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: 60px;
	justify-content: space-evenly;
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.marquee {
	position: absolute;
	white-space: nowrap;
	overflow: hidden;
	min-width: 100%;
	height: 100%;
	margin: auto;
}
.marqueeOne {
	-webkit-animation: scrollLeft 100s ease infinite;
	animation: scrollLeft 100s ease infinite;
}
.marqueeTwo {
	-webkit-animation: scrollRight 100s ease infinite;
	animation: scrollRight 100s ease infinite;
}

@-webkit-keyframes scrollLeft {
	from {
		transform: translateX(-20%);
	}
	to {
		transform: translateX(20%);
	}
}

@keyframes scrollLeft {
	from {
		transform: translateX(-20%);
	}
	to {
		transform: translateX(20%);
	}
}
@-webkit-keyframes scrollRight {
	from {
		transform: translateX(20%);
	}
	to {
		transform: translateX(-20%);
	}
}

@keyframes scrollRight {
	from {
		transform: translateX(20%);
	}
	to {
		transform: translateX(-20%);
	}
}
