@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda&family=Playfair+Display:ital@0;1&family=Pontano+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
	--prata: "Bodoni Moda", serif;
	--pontano: "Pontano Sans", sans-serif;
	--red: #fa4416;
}

body {
	background-color: black;
	color: #f1ebe6;
}
.fullPage {
	position: relative;
	min-width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	min-height: 100vh;
	overflow: hidden;
}
.clampedFullPage {
	min-width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	min-height: 100vh;
	position: relative;
	overflow: hidden;
	background: transparent;
	z-index: -1;
}

.flexCol {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
}

.flexRow {
	display: flex;
	justify-content: center;
	align-items: center;
}

.heading {
	font-family: "Bodoni Moda", serif;
	font-family: var(--prata);
	color: white;
	font-size: 18px;
	text-align: center;
	letter-spacing: 6px;
	font-weight: lighter;
	transition: 1s ease;
}

.smallText {
	font-family: "Pontano Sans", sans-serif;
	font-family: var(--pontano);
	font-size: 15px;
	position: absolute;
	bottom: 0;
	width: 45%;
	transition: 1s ease;
	transition-delay: 1.5s;
	z-index: 850;
}
.smallPontanoText {
	font-family: "Pontano Sans", sans-serif;
	font-family: var(--pontano);
	letter-spacing: 3px;
	font-size: 14px;
}
.img {
	position: absolute;
	object-fit: cover;
	border-radius: 5px;
	transition: all 0.5s ease;
}

.smallImg {
	width: 25%;
	bottom: 25%;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center bottom;
}

.underScoreAnimation {
	background-image: linear-gradient(white, white);
	background-repeat: no-repeat;
	background-position: left bottom;
}
.fadeIn {
	animation: fadingIn ease 2s;
}
@keyframes fadingIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.drawARCS {
	stroke-dasharray: 1;
	stroke-dashoffset: 1;
	animation: dash 2s linear;
	animation-fill-mode: forwards;
	transition: ease;
}

@keyframes arcs {
	from {
		stroke-dashoffset: 1;
	}
	to {
		stroke-dashoffset: 0;
	}
}

.navButtons {
	padding: 8px 0;
	margin: 15px;
	font-family: "Pontano Sans", sans-serif;
	font-family: var(--pontano);
	font-weight: lighter;
	font-size: 18px;
	color: white;
	letter-spacing: 5px;
	transition: 0.3s ease;
}

.navContainer {
	position: relative;
	overflow: hidden;
	cursor: pointer;
	background-image: linear-gradient(#fa4416, #fa4416);
	background-image: linear-gradient(var(--red), var(--red));
	background-size: 0% 5px;
	background-repeat: no-repeat;
	background-position: left bottom;
	transition: font-size 500ms ease, background-size 550ms ease;
}
.navContainer:before {
	content: attr(data-before);
	text-align: center;
	font-family: "Bodoni Moda", serif;
	font-family: var(--prata);
	font-size: 14px;
	letter-spacing: 5px;
	color: white;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	height: 0%;
	transition: 0.3s linear;
}

.navContainer:hover {
	background-size: 100% 1px;
}
.navContainer:hover > .navButtons {
	transform: translateY(-300%);
}
.navContainer:hover:before {
	height: 70%;
}

.scrollLeftAnim {
	white-space: nowrap;
	animation: leftScroll 100s ease infinite;
}

@keyframes leftScroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}

@media (max-width: 768px) {
	.smallText {
		font-size: 14px;
	}
}
.orbit {
	animation-name: orbit-animation;
	animation-duration: 10s;
	animation-iteration-count: infinite;
}
@keyframes orbit-animation {
	from {
		transform: rotate(0);
		animation-timing-function: linear;
	}

	to {
		transform: rotate(360deg);
		animation-timing-function: linear;
	}
}

.loaders_drawLogo__3qXpj {
	stroke-dasharray: 1;
	stroke-dashoffset: 1;
	animation: loaders_dash__1pvZV 2s linear;
	animation-fill-mode: forwards;
	transition: ease;
	animation-iteration-count: infinite;
}

@keyframes loaders_dash__1pvZV {
	from {
		stroke-dashoffset: 1;
	}
	to {
		stroke-dashoffset: 0;
	}
}

.loaders_fadeIn__pjlxO {
	animation: loaders_fade__3z5hK 2s ease;
	animation-iteration-count: infinite;
}

@keyframes loaders_fade__3z5hK {
	from {
		opacity: 0;
	}
	99% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.header_sideMenuVis__1p9Ag {
	animation: header_opening__15bKC ease 1.5s;
}
@keyframes header_opening__15bKC {
	0% {
		width: 0;
		opacity: 0;
	}
	100% {
		width: 50vw;
		opacity: 1;
	}
}
.header_sideMenu__3_puJ {
	position: absolute;
	left: 0;
	top: 0;
	height: 100vh;
	transition: 1s ease;
	background-color: black;
	border-right: white solid 1px;
	width: 50vw;
}

.header_sideMenuHidden__Twkf8 {
	display: none;
}

.header_navButtons__2uRoZ {
	padding: 8px 0;
	margin: 15px;
	font-family: var(--pontano);
	font-weight: lighter;
	font-size: 18px;
	color: white;
	letter-spacing: 5px;
	transition: 0.3s ease;
}

.header_navContainer__1bjjO {
	position: relative;
	overflow: hidden;
	cursor: pointer;
	background-image: linear-gradient(var(--red), var(--red));
	background-size: 0% 5px;
	background-repeat: no-repeat;
	background-position: left bottom;
	transition: font-size 500ms ease, background-size 550ms ease;
}
.header_navContainer__1bjjO:before {
	content: attr(data-before);
	text-align: center;
	font-family: var(--prata);
	font-size: 14px;
	letter-spacing: 5px;
	color: white;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	height: 0%;
	transition: 0.3s linear;
}

.header_navContainer__1bjjO:hover {
	background-size: 100% 1px;
}
.header_navContainer__1bjjO:hover > .header_navButtons__2uRoZ {
	transform: translateY(-300%);
}
.header_navContainer__1bjjO:hover:before {
	height: 70%;
}
.header_burger__3hq9Z {
	transition: 500ms ease;
}
.header_burgerContainer__3RloC {
	position: absolute;
	cursor: pointer;
	left: 5%;
	z-index: 1050;
}

.header_svgBurgerContainer__3kXRl {
	width: 100%;
	height: 100%;
}

.header_svgBurgerContainer__3kXRl:hover > .header_burgerGrow__21aiT {
	transform: scaleX(1.5);
}
.header_svgBurgerContainer__3kXRl:hover > .header_burgerShrink__31Qod {
	transform: scaleX(0.5);
}

.header_logoParent__3a5d7 {
	cursor: pointer;
	width: 100%;
	height: 100%;
}
.header_logoParent__3a5d7:hover > .header_drawLogo__32Ols {
	stroke-dasharray: 1;
	stroke-dashoffset: 1;
	animation: header_logo__1Uyk- 2s linear;
	animation-fill-mode: forwards;
	transition: 2s ease;
	animation-iteration-count: infinite;
}

@keyframes header_logo__1Uyk- {
	from {
		stroke-dashoffset: 1;
	}
	to {
		stroke-dashoffset: 0;
	}
}
.header_logoParent__3a5d7:hover > .header_fadeIn__1cWRi {
	transition: 2s ease;
	animation: header_fade__1-SDh 2s ease;
	animation-iteration-count: infinite;
}

@keyframes header_fade__1-SDh {
	from {
		opacity: 0;
	}
	99% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.header_logoContainer__1HrFE {
	position: relative;
	margin-top: 50px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: 400px;
	max-height: 100px;
	cursor: pointer;
	overflow: hidden;
}
.header_logoText__5zUGf {
	transition: 1s ease;
	position: absolute;
	top: 65%;
	left: 50%;
	transform: translateX(-60%);
	font-family: "Work sans", sans-serif;
	letter-spacing: 5px;
}
.header_logoContainer__1HrFE:hover > .header_logoText__5zUGf {
	transform: translate(-55%, 300%);
}

.mainButton_buttonLabel__2CIEa {
	text-align: center;
	font-family: var(--pontano);
	font-size: 16px;
	letter-spacing: 3px;
	transition: 0.2s ease;
	position: absolute;
	transform: translate(-50%, -50%);
	width: 100%;
}
.mainButton_button__2jEyy {
	position: absolute;
	max-height: 5rem;
	min-height: 5rem;
	min-width: 350px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 0.5rem;
	border-radius: 5px;
	background: transparent;
	border: none;
	outline: none;
	transition: 0.3s ease;
	color: white;
	overflow: hidden;
	background-image: linear-gradient(var(--red), var(--red));
	background-size: 0% 5px;
	background-repeat: no-repeat;
	background-position: left bottom;
	z-index: 850;
}

.mainButton_button__2jEyy:before {
	content: attr(data-label);
	text-align: center;
	font-family: var(--prata);
	letter-spacing: 3px;
	font-size: 14px;
	color: white;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	height: 0%;
	border-radius: 5px;
	transition: 0.2s linear;
}
.mainButton_button__2jEyy:after {
	content: url(/static/media/redArrow2.32469754.svg);
	position: absolute;
	height: 20%;
	width: 20%;
	bottom: 10%;
	right: 10%;
	transition: 0.2s linear;
}
.mainButton_button__2jEyy:hover {
	background-size: 40% 1px;
	cursor: pointer;
	outline: none;
}
.mainButton_button__2jEyy:hover:before {
	height: 70%;
}
.mainButton_button__2jEyy:hover:after {
	transform: translateX(20%);
}

.mainButton_button__2jEyy:hover > .mainButton_buttonLabel__2CIEa {
	transform: translate(-50%, -500%);
}
.mainButton_button__2jEyy:focus {
	outline: none;
}
@media (max-width: 1000px) {
	.mainButton_button__2jEyy {
		min-width: 250px;
	}
}

.l3_videoContainer__2g6Nl {
	position: absolute;
	top: 50%;
	overflow: hidden;
	transition: 1.5s ease;
	transition-delay: 2s;
}
.l3_videoPlayer__3HtHa {
	position: absolute;
	top: 0;
	height: 80%;
	width: 100%;
	transition: 2.5s ease;
	object-fit: cover;
	transition-delay: 2s;
}
.l3_rollingText__1dEke {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.form_form__2wGvh {
	align-items: flex-start;
	width: 100%;
	height: 100%;
}
.form_formLabels__2kdo4 {
	margin: 15px 0;
	transition: 500ms linear;
}
.form_formInputs__2eeAM {
	color: white;
	font-family: var(--pontano);
	letter-spacing: 3px;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid white;
	outline: none;
	-webkit-appearance: none;
	appearance: none;
	background: none;
	transition-duration: 1s;
	transition-timing-function: linear;
}

.form_sendArrow__2LSGk {
	width: 50px;
	height: 15px;
	cursor: pointer;
	margin-top: 20px;
}

.marquees_wrapper__3yyFG {
	position: relative;
	width: 100vw;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: 60px;
	justify-content: space-evenly;
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.marquees_marquee__3tirw {
	position: absolute;
	white-space: nowrap;
	overflow: hidden;
	min-width: 100%;
	height: 100%;
	margin: auto;
}
.marquees_marqueeOne__2bwj2 {
	animation: marquees_scrollLeft__34PtH 100s ease infinite;
}
.marquees_marqueeTwo__IbpIZ {
	animation: marquees_scrollRight__3hZ7M 100s ease infinite;
}

@keyframes marquees_scrollLeft__34PtH {
	from {
		transform: translateX(-20%);
	}
	to {
		transform: translateX(20%);
	}
}

@keyframes marquees_scrollRight__3hZ7M {
	from {
		transform: translateX(20%);
	}
	to {
		transform: translateX(-20%);
	}
}

:root {
	--beige: #aba79a;
}
.NL_mainContainer__1R4-l {
	height: 500vh;
	width: 100vw;
	background: black;
	position: relative;
}
.NL_backgroundText__lAHfH {
	font-family: "Work Sans", sans-serif;
	font-weight: 800;
	color: #aba79a;
	color: var(--beige);
	opacity: 0.2;
	position: fixed;
	top: 20vh;
	left: 0;
	font-size: 35vw;
	width: 500vh;
	height: 50vw;
	white-space: nowrap;
	z-index: -2;
}
.NL_bgimg__-kuCv {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -2;
	width: 1460px;
	height: 880px;
	object-fit: cover;
	transition: 1s ease;
}
.NL_paginatorContainer__1-vdy {
	top: 10%;
	left: 1vw;
	width: 15%;
	height: 200px;
	position: fixed;
	justify-content: space-evenly;
	overflow: hidden;
	color: #aba79a;
	color: var(--beige);
	font-size: 100px;
	z-index: 2;
}

.NL_movingDigitWrapper__32Amh {
	position: relative;
	height: 100%;
	width: 30%;
	overflow: hidden;
}
.NL_textContainer__2MaA7 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50%;
	height: 90%;
	z-index: 2;
}
.NL_titleWrapper__iaIuL {
	width: 100%;
	height: 30%;
	letter-spacing: 15px;
	font-family: "Work Sans", sans-serif;
	font-size: 3vw;
	font-weight: bolder;
	align-items: flex-start;
}
.NL_textWrapper__19yfm {
	width: 70%;
	align-items: flex-start;
	z-index: 1;
}
.NL_H2_body__lrJEQ {
	font-family: var(--pontano);
	font-size: 14px;
	letter-spacing: 3px;
	font-weight: bold;
}

.NL_videoContainer__3NcQl {
	position: absolute;
	right: 20%;
	bottom: 25%;
	width: 300px;
	height: 200px;
	transition: 1s ease;
}
.NL_orbit__11klo {
	transform-style: preserve-3d;
	transform-origin: center center;
	animation-name: NL_orbit-animation__ga60p;
	animation-duration: 10s;
	animation-iteration-count: infinite;
	z-index: 0;
}
@keyframes NL_orbit-animation__ga60p {
	from {
		transform: rotateY(0) rotateX(25deg);
		animation-timing-function: linear;
	}

	to {
		transform: rotateY(360deg) rotateX(25deg);
		animation-timing-function: linear;
	}
}
@media (max-width: 1200px) {
	.NL_textWrapper__19yfm {
		width: 100%;
	}
	.NL_paginatorContainer__1-vdy {
		width: 150px;
		height: 150px;
		font-size: 50px;
	}
	.NL_bgimg__-kuCv {
		width: 100vw;
		height: 100vh;
	}
	.NL_H2_body__lrJEQ {
		width: 100%;
	}
	.NL_H4_body__1s5Aw {
		margin-left: auto;
	}
	.NL_videoContainer__3NcQl {
		bottom: 5%;
		left: 10%;
		width: 200px;
	}
}
@media (max-width: 700px) {
	.NL_textContainer__2MaA7 {
		top: 60%;
		width: 80vw;
	}
}

