.sideMenuVis {
	-webkit-animation: opening ease 1.5s;
	animation: opening ease 1.5s;
}

@-webkit-keyframes opening {
	0% {
		width: 0;
		opacity: 0;
	}
	100% {
		width: 50vw;
		opacity: 1;
	}
}
@keyframes opening {
	0% {
		width: 0;
		opacity: 0;
	}
	100% {
		width: 50vw;
		opacity: 1;
	}
}
.sideMenu {
	position: absolute;
	left: 0;
	top: 0;
	height: 100vh;
	transition: 1s ease;
	background-color: black;
	border-right: white solid 1px;
	width: 50vw;
}

.sideMenuHidden {
	display: none;
}

.navButtons {
	padding: 8px 0;
	margin: 15px;
	font-family: var(--pontano);
	font-weight: lighter;
	font-size: 18px;
	color: white;
	letter-spacing: 5px;
	transition: 0.3s ease;
}

.navContainer {
	position: relative;
	overflow: hidden;
	cursor: pointer;
	background-image: linear-gradient(var(--red), var(--red));
	background-size: 0% 5px;
	background-repeat: no-repeat;
	background-position: left bottom;
	transition: font-size 500ms ease, background-size 550ms ease;
}
.navContainer:before {
	content: attr(data-before);
	text-align: center;
	font-family: var(--prata);
	font-size: 14px;
	letter-spacing: 5px;
	color: white;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	height: 0%;
	transition: 0.3s linear;
}

.navContainer:hover {
	background-size: 100% 1px;
}
.navContainer:hover > .navButtons {
	transform: translateY(-300%);
}
.navContainer:hover:before {
	height: 70%;
}
.burger {
	transition: 500ms ease;
}
.burgerContainer {
	position: absolute;
	cursor: pointer;
	left: 5%;
	z-index: 1050;
}

.svgBurgerContainer {
	width: 100%;
	height: 100%;
}

.svgBurgerContainer:hover > .burgerGrow {
	transform: scaleX(1.5);
}
.svgBurgerContainer:hover > .burgerShrink {
	transform: scaleX(0.5);
}

.logoParent {
	cursor: pointer;
	width: 100%;
	height: 100%;
}
.logoParent:hover > .drawLogo {
	stroke-dasharray: 1;
	stroke-dashoffset: 1;
	-webkit-animation: logo 2s linear;
	animation: logo 2s linear;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	transition: 2s ease;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

@-webkit-keyframes logo {
	from {
		stroke-dashoffset: 1;
	}
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes logo {
	from {
		stroke-dashoffset: 1;
	}
	to {
		stroke-dashoffset: 0;
	}
}
.logoParent:hover > .fadeIn {
	transition: 2s ease;
	-webkit-animation: fade 2s ease;
	animation: fade 2s ease;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}
@-webkit-keyframes fade {
	from {
		opacity: 0;
	}
	99% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fade {
	from {
		opacity: 0;
	}
	99% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.logoContainer {
	position: relative;
	margin-top: 50px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: 400px;
	max-height: 100px;
	cursor: pointer;
	overflow: hidden;
}
.logoText {
	transition: 1s ease;
	position: absolute;
	top: 65%;
	left: 50%;
	transform: translateX(-60%);
	font-family: "Work sans", sans-serif;
	letter-spacing: 5px;
}
.logoContainer:hover > .logoText {
	transform: translate(-55%, 300%);
}
