.form {
	align-items: flex-start;
	width: 100%;
	height: 100%;
}
.formLabels {
	margin: 15px 0;
	transition: 500ms linear;
}
.formInputs {
	color: white;
	font-family: var(--pontano);
	letter-spacing: 3px;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid white;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: none;
	transition-duration: 1s;
	transition-timing-function: linear;
}

.sendArrow {
	width: 50px;
	height: 15px;
	cursor: pointer;
	margin-top: 20px;
}
