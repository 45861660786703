.buttonLabel {
	text-align: center;
	font-family: var(--pontano);
	font-size: 16px;
	letter-spacing: 3px;
	transition: 0.2s ease;
	position: absolute;
	transform: translate(-50%, -50%);
	width: 100%;
}
.button {
	position: absolute;
	max-height: 5rem;
	min-height: 5rem;
	min-width: 350px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 0.5rem;
	border-radius: 5px;
	background: transparent;
	border: none;
	outline: none;
	transition: 0.3s ease;
	color: white;
	overflow: hidden;
	background-image: linear-gradient(var(--red), var(--red));
	background-size: 0% 5px;
	background-repeat: no-repeat;
	background-position: left bottom;
	z-index: 850;
}

.button:before {
	content: attr(data-label);
	text-align: center;
	font-family: var(--prata);
	letter-spacing: 3px;
	font-size: 14px;
	color: white;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	height: 0%;
	border-radius: 5px;
	transition: 0.2s linear;
}
.button:after {
	content: url("../../Assets/images/icons/redArrow2.svg");
	position: absolute;
	height: 20%;
	width: 20%;
	bottom: 10%;
	right: 10%;
	transition: 0.2s linear;
}
.button:hover {
	background-size: 40% 1px;
	cursor: pointer;
	outline: none;
}
.button:hover:before {
	height: 70%;
}
.button:hover:after {
	transform: translateX(20%);
}

.button:hover > .buttonLabel {
	transform: translate(-50%, -500%);
}
.button:focus {
	outline: none;
}
@media (max-width: 1000px) {
	.button {
		min-width: 250px;
	}
}
