.videoContainer {
	position: absolute;
	top: 50%;
	overflow: hidden;
	transition: 1.5s ease;
	transition-delay: 2s;
}
.videoPlayer {
	position: absolute;
	top: 0;
	height: 80%;
	width: 100%;
	transition: 2.5s ease;
	-o-object-fit: cover;
	object-fit: cover;
	transition-delay: 2s;
}
.rollingText {
	position: absolute;
	bottom: 0;
	width: 100%;
}
