@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;700;800&display=swap");

:root {
	--beige: #aba79a;
}
.mainContainer {
	height: 500vh;
	width: 100vw;
	background: black;
	position: relative;
}
.backgroundText {
	font-family: "Work Sans", sans-serif;
	font-weight: 800;
	color: var(--beige);
	opacity: 0.2;
	position: fixed;
	top: 20vh;
	left: 0;
	font-size: 35vw;
	width: 500vh;
	height: 50vw;
	white-space: nowrap;
	z-index: -2;
}
.bgimg {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -2;
	width: 1460px;
	height: 880px;
	-o-object-fit: cover;
	object-fit: cover;
	transition: 1s ease;
}
.paginatorContainer {
	top: 10%;
	left: 1vw;
	width: 15%;
	height: 200px;
	position: fixed;
	justify-content: space-evenly;
	overflow: hidden;
	color: var(--beige);
	font-size: 100px;
	z-index: 2;
}

.movingDigitWrapper {
	position: relative;
	height: 100%;
	width: 30%;
	overflow: hidden;
}
.textContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50%;
	height: 90%;
	z-index: 2;
}
.titleWrapper {
	width: 100%;
	height: 30%;
	letter-spacing: 15px;
	font-family: "Work Sans", sans-serif;
	font-size: 3vw;
	font-weight: bolder;
	align-items: flex-start;
}
.textWrapper {
	width: 70%;
	align-items: flex-start;
	z-index: 1;
}
.H2_body {
	font-family: var(--pontano);
	font-size: 14px;
	letter-spacing: 3px;
	font-weight: bold;
}

.videoContainer {
	position: absolute;
	right: 20%;
	bottom: 25%;
	width: 300px;
	height: 200px;
	transition: 1s ease;
}
.orbit {
	transform-style: preserve-3d;
	transform-origin: center center;
	-webkit-animation-name: orbit-animation;
	animation-name: orbit-animation;
	-webkit-animation-duration: 10s;
	animation-duration: 10s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	z-index: 0;
}
@-webkit-keyframes orbit-animation {
	from {
		transform: rotateY(0) rotateX(25deg);
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}

	to {
		transform: rotateY(360deg) rotateX(25deg);
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
}
@keyframes orbit-animation {
	from {
		transform: rotateY(0) rotateX(25deg);
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}

	to {
		transform: rotateY(360deg) rotateX(25deg);
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
}
@media (max-width: 1200px) {
	.textWrapper {
		width: 100%;
	}
	.paginatorContainer {
		width: 150px;
		height: 150px;
		font-size: 50px;
	}
	.bgimg {
		width: 100vw;
		height: 100vh;
	}
	.H2_body {
		width: 100%;
	}
	.H4_body {
		margin-left: auto;
	}
	.videoContainer {
		bottom: 5%;
		left: 10%;
		width: 200px;
	}
}
@media (max-width: 700px) {
	.textContainer {
		top: 60%;
		width: 80vw;
	}
}
