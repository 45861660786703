@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda&family=Playfair+Display:ital@0;1&family=Pontano+Sans&display=swap");
:root {
	--prata: "Bodoni Moda", serif;
	--pontano: "Pontano Sans", sans-serif;
	--red: #fa4416;
}

body {
	background-color: black;
	color: #f1ebe6;
}
.fullPage {
	position: relative;
	min-width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	min-height: 100vh;
	overflow: hidden;
}
.clampedFullPage {
	min-width: 100vw;
	max-width: 100vw;
	max-height: 100vh;
	min-height: 100vh;
	position: relative;
	overflow: hidden;
	background: transparent;
	z-index: -1;
}

.flexCol {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
}

.flexRow {
	display: flex;
	justify-content: center;
	align-items: center;
}

.heading {
	font-family: var(--prata);
	color: white;
	font-size: 18px;
	text-align: center;
	letter-spacing: 6px;
	font-weight: lighter;
	transition: 1s ease;
}

.smallText {
	font-family: var(--pontano);
	font-size: 15px;
	position: absolute;
	bottom: 0;
	width: 45%;
	transition: 1s ease;
	transition-delay: 1.5s;
	z-index: 850;
}
.smallPontanoText {
	font-family: var(--pontano);
	letter-spacing: 3px;
	font-size: 14px;
}
.img {
	position: absolute;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 5px;
	transition: all 0.5s ease;
}

.smallImg {
	width: 25%;
	bottom: 25%;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center bottom;
}

.underScoreAnimation {
	background-image: linear-gradient(white, white);
	background-repeat: no-repeat;
	background-position: left bottom;
}
.fadeIn {
	-webkit-animation: fadingIn ease 2s;
	animation: fadingIn ease 2s;
}

@-webkit-keyframes fadingIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadingIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.drawARCS {
	stroke-dasharray: 1;
	stroke-dashoffset: 1;
	-webkit-animation: dash 2s linear;
	animation: dash 2s linear;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	transition: ease;
}

@-webkit-keyframes arcs {
	from {
		stroke-dashoffset: 1;
	}
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes arcs {
	from {
		stroke-dashoffset: 1;
	}
	to {
		stroke-dashoffset: 0;
	}
}

.navButtons {
	padding: 8px 0;
	margin: 15px;
	font-family: var(--pontano);
	font-weight: lighter;
	font-size: 18px;
	color: white;
	letter-spacing: 5px;
	transition: 0.3s ease;
}

.navContainer {
	position: relative;
	overflow: hidden;
	cursor: pointer;
	background-image: linear-gradient(var(--red), var(--red));
	background-size: 0% 5px;
	background-repeat: no-repeat;
	background-position: left bottom;
	transition: font-size 500ms ease, background-size 550ms ease;
}
.navContainer:before {
	content: attr(data-before);
	text-align: center;
	font-family: var(--prata);
	font-size: 14px;
	letter-spacing: 5px;
	color: white;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	height: 0%;
	transition: 0.3s linear;
}

.navContainer:hover {
	background-size: 100% 1px;
}
.navContainer:hover > .navButtons {
	transform: translateY(-300%);
}
.navContainer:hover:before {
	height: 70%;
}

.scrollLeftAnim {
	white-space: nowrap;
	-webkit-animation: leftScroll 100s ease infinite;
	animation: leftScroll 100s ease infinite;
}

@-webkit-keyframes leftScroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}

@keyframes leftScroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}

@media (max-width: 768px) {
	.smallText {
		font-size: 14px;
	}
}
.orbit {
	-webkit-animation-name: orbit-animation;
	animation-name: orbit-animation;
	-webkit-animation-duration: 10s;
	animation-duration: 10s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}
@-webkit-keyframes orbit-animation {
	from {
		transform: rotate(0);
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}

	to {
		transform: rotate(360deg);
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
}
@keyframes orbit-animation {
	from {
		transform: rotate(0);
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}

	to {
		transform: rotate(360deg);
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
}
